var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "IntersectionObserver",
    {
      attrs: {
        "observe-once": "",
        options: {
          threshold: [0.0],
          trackVisibility: true,
          delay: 100
        }
      },
      on: { intersect: _vm.onIntersect }
    },
    [
      _c("img", {
        class: _vm.classes,
        style: _vm.styles,
        attrs: { src: _vm.image, title: "Press Left Click + Ctrl to open" },
        on: {
          click: function($event) {
            $event.preventDefault()
            if (!$event.ctrlKey) {
              return null
            }
            return _vm.clickToSource(_vm.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }